export const actionTypes = {
  GET_FORM_BY_TOKEN: 'signatures/GET_FORM_BY_TOKEN',
  GET_FORM_BY_TOKEN_SUCCESS: 'signatures/GET_FORM_BY_TOKEN_SUCCESS',
  GET_FORM_BY_TOKEN_FAILED: 'signatures/GET_FORM_BY_TOKEN_FAILED',
  SAVE_FORM_INPUT: 'signatures/SAVE_FORM_INPUT',
  SAVE_FORM_INPUT_SUCCESS: 'signatures/SAVE_FORM_INPUT_SUCCESS',
  SAVE_FORM_INPUT_FAILED: 'signatures/SAVE_FORM_INPUT_FAILED',
};

export const actions = {
  getFormByToken: (payload) => ({
    type: actionTypes.GET_FORM_BY_TOKEN,
    payload,
  }),
  getFormByTokenFailed: (payload) => ({
    type: actionTypes.GET_FORM_BY_TOKEN_FAILED,
    payload,
  }),
  getFormByTokenSuccess: (payload) => ({
    type: actionTypes.GET_FORM_BY_TOKEN_SUCCESS,
    payload,
  }),
  saveFormInput: (payload) => ({
    type: actionTypes.SAVE_FORM_INPUT,
    payload,
  }),
  saveFormInputFailed: (payload) => ({
    type: actionTypes.SAVE_FORM_INPUT_FAILED,
    payload,
  }),
  saveFormInputSuccess: (payload) => ({
    type: actionTypes.SAVE_FORM_INPUT_SUCCESS,
    payload,
  }),
};
