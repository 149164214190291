import { actionTypes } from '../actions/auth';

const initialState = {
  isLoggedIn: false,
  userData: {},
  error: null,
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        isLoggedIn: false,
        userData: {},
        error: null,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        userData: payload,
        error: null,
      };
    case actionTypes.LOGIN_FAILED:
      return {
        ...state,
        isLoggedIn: false,
        userData: {},
        error: payload,
      };
    default:
      return state;
  }
};

export default authReducer;
