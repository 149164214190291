import { spawn } from 'redux-saga/effects';

// Sagas
import authSaga from './auth';
import signaturesSaga from './signatures';

// Export the root saga
export default function* rootSaga() {
  yield spawn(authSaga);
  yield spawn(signaturesSaga);
}
