import { put, call, takeLatest } from 'redux-saga/effects';
import { actionTypes, actions } from '@store/actions/auth';
import {
  login as loginService,
  isLoggedIn,
  getTokenInfo,
} from '@services/auth';

function* loginSagas({ payload }) {
  try {
    const data = yield call(loginService, payload);
    yield put(actions.loginSuccess(data));
    window.location.href = '/dashboard';
  } catch (error) {
    yield put(actions.loginFailed(error));
  }
}

function* rehydrateApp() {
  try {
    if (isLoggedIn()) {
      yield put(actions.loginSuccess(getTokenInfo()));
      return;
    }
  } catch (error) {
    yield put(actions.loginFailed(error));
  }
}

export default function* authSaga() {
  yield takeLatest(actionTypes.LOGIN, loginSagas);
  yield takeLatest(actionTypes.REHYDRATE, rehydrateApp);
}
