import axios from 'axios';

// eslint-disable-next-line no-undef
const tokenName = process.env.REACT_APP_TOKEN_NAME;
export const getToken = () => localStorage.getItem(tokenName);

export const storeToken = (token) => {
  localStorage.setItem(tokenName, token);
};

export const VALID_STATUSES = [200, 201, 204];

// eslint-disable-next-line no-undef
const { REACT_APP_API_URL } = process.env;

const headers = {
  'Content-Type': 'application/json',
};

const TIMEOUT = 30000;

export const client = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: TIMEOUT,
  headers,
});

const headersAuth = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${getToken()}`,
};

export const authenticatedClient = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: TIMEOUT,
  headers: headersAuth,
});
