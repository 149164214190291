export default class UnhandledException extends Error {
  constructor(message, stack, ...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnhandledException);
    }
    this.name = 'UnhandledException';
    this.code = 'UnhandledException';
    this.message = message;
    this.stack = stack;
    this.date = new Date();
  }
}
