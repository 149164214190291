import decode from 'jwt-decode';
import { client, getToken, storeToken } from './api';

export const isLoggedIn = () => {
  const token = getToken();
  return token !== null && token !== '';
};

export const getTokenInfo = () => decode(getToken());

export const isTokenExpired = () => {
  if (!isLoggedIn()) return false;
  const data = getTokenInfo();
  return Date.now() >= data.exp * 1000;
};

export const login = async (request) => {
  const response = await client.post('/auth/', request);

  if (response.status !== 200) {
    throw Error();
  }
  storeToken(response.data.access);
  return decode(response.data.access);
};
