/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Switch } from 'react-router-dom';
import { routes, RouteWithSubRoutes } from './config/routes';
import configureStore, { history } from './store';

const store = configureStore();

function App() {
  return (
    <div className="bg-default-gray">
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            {routes.map((route) => (
              <RouteWithSubRoutes key={route.path} {...route} />
            ))}
          </Switch>
        </ConnectedRouter>
      </Provider>
    </div>
  );
}

export default App;
