import _sortBy from 'lodash/sortBy';
import { actionTypes } from '@store/actions/signatures';

const initialState = {
  form: {
    data: {},
    success: true,
    errorCode: '',
    errorMessage: '',
  },
};

const getFormByToken = (state, payload) => {
  const filtered = payload.inputs.filter((input) => !input.isSet);
  const ordered = _sortBy(filtered, ['position']);

  const completedSignatures = payload.inputs.filter(input => input.isSet).length;
  const amountOfSignatures = payload.inputs.length;

  return {
    ...state,
    form: {
      ...state.form,
      data: { ...payload, inputs: ordered, amountOfSignatures, completedSignatures },
      success: true,
      errorCode: '',
      errorMessage: '',
    },
  };
};

const signaturesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_FORM_BY_TOKEN:
      return {
        ...state,
        form: {
          data: {},
          success: true,
          errorCode: '',
          errorMessage: '',
        },
      };
    case actionTypes.GET_FORM_BY_TOKEN_SUCCESS:
      return getFormByToken(state, payload);

    case actionTypes.GET_FORM_BY_TOKEN_FAILED:
      return {
        ...state,
        form: {
          ...state.form,
          data: {},
          success: false,
          errorCode: payload.code,
          errorMessage: payload.message,
        },
      };
    case actionTypes.SAVE_FORM_INPUT:
      return {
        ...state,
        input: {
          data: {},
          success: true,
          errorCode: '',
          errorMessage: '',
        },
      };
    case actionTypes.SAVE_FORM_INPUT_SUCCESS:
      return {
        ...state,
        input: {
          ...state.form,
          data: payload,
          success: true,
          errorCode: '',
          errorMessage: '',
        },
      };
    case actionTypes.SAVE_FORM_INPUT_FAILED:
      return {
        ...state,
        input: {
          ...state.form,
          data: {},
          success: false,
          errorCode: payload.code,
          errorMessage: payload.message,
        },
      };
    default:
      return state;
  }
};

export default signaturesReducer;
