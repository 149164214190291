import React, { useEffect, useState } from 'react';
import _ from 'lodash-contrib';
import { SignaturePad, SimpleHeader, Card, Loader } from 'casanova-commons';

import { useWindowSize } from '@hooks';
import i18n from '@utils/i18n';

// TODO: Fix Loader position in commons
import './Signatures.css';

function SignaturesPage({
  getFormByToken,
  history,
  saveFormInput,
  currentInput,
  showLoader,
  form = {},
  formSuccess = false,
}) {
  const [hasError, setHasError] = useState(false);
  const [showSignatureButtons, setShowSignatureButtons] = useState(true);
  const { width, height } = useWindowSize();

  const { amountOfSignatures = 0, completedSignatures = 0 } = form;

  useEffect(() => {
    const cleanedUrl = history.location.search.replace('?', '');
    const queryParams = _.fromQuery(cleanedUrl);
    getFormByToken(queryParams.form);
  }, []);

  const handleOnComplete = (data, isEmpty) => {
    // eslint-disable-next-line no-console
    console.log(completedSignatures, data);
    if(completedSignatures === 0 || completedSignatures === 4 || completedSignatures === 5){
      setHasError(isEmpty);
      if (!isEmpty) {
        setShowSignatureButtons(false);
        saveFormInput({ signature: data });
      }
    } else {
        setShowSignatureButtons(false);
        if (!isEmpty) {
          saveFormInput({ signature: data, checkbox: true });
        } else {
          saveFormInput({ signature: data, checkbox: false });
        }
    }
  };

  return (
    <main>
      <Loader show={showLoader} />
      <SimpleHeader infoText={currentInput.name} />
      <div className="flex justify-center">
        <div className="flex justify-center w-full md:w-5/6 mt-3">
          <Card className="SignaturesPage bg-white ">
            <div className="flex justify-between">
              <p className="text-gray-500 mb-3">
                {i18n('SIGNATURES__PAGE_DISCLAIMER')}
              </p>
              {formSuccess &&
                <h6 className="text-secondary font-bold">
                  {i18n('SIGNATURES__PAGE_COUNT_OF_SIGNATURES', [
                    completedSignatures + 1,
                    amountOfSignatures
                  ])}
                </h6>
              }
            </div>
            <SignaturePad
              width={width >= 768 ? undefined : width * 0.9}
              height={width >= 768 ? undefined : height * 0.55}
              showButtons={showSignatureButtons}
              onComplete={handleOnComplete}
              penColor="rgb(0, 0, 139)"
            />
            {hasError && (
              <div className="text-red-400 text-base font-semibold">
                {i18n('ERROR__SIGNATURES__EMPTY')}
              </div>
            )}
          </Card>
        </div>
      </div>
    </main>
  );
}

export default SignaturesPage;
