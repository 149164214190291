import { ApiException, UnhandledException } from '@utils/exceptions';
import { client, VALID_STATUSES } from './api';

const root = '/pricequotes/v1';

export const getPendingSignatures = async (token) => {
  try {
    const response = await client.get(`${root}/forms/public?token=${token}`);
    if (VALID_STATUSES.indexOf(response.status)) {
      const { errorCode, message } = response.data;
      throw new ApiException(errorCode, message);
    }
    return response.data.data;
  } catch (error) {
    const { message, stack } = error;
    throw new UnhandledException(message, stack);
  }
};

export const saveSignature = async (inputUuid, request) => {
  try {
    const response = await client.post(
      `${root}/forms/inputs/${inputUuid}`,
      request,
    );
    if (VALID_STATUSES.indexOf(response.status)) {
      const { errorCode, message } = response.data;
      throw new ApiException(errorCode, message);
    }
    return response.data;
  } catch (error) {
    const { message, stack } = error;
    throw new UnhandledException(message, stack);
  }
};

// export const getPendingSignatures = async () => formMock.data;
