import { actionTypes } from '../actions/app';

const initialState = {
  loader: false,
};

// eslint-disable-next-line no-unused-vars
const appReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SHOW_LOADER:
      return {
        ...state,
        loader: true,
      };
    case actionTypes.HIDE_LOADER:
      return {
        ...state,
        loader: false,
      };
    default:
      return state;
  }
};

export default appReducer;
