import React from 'react';
import { SimpleHeader, Card } from 'casanova-commons';

import rentaCar from '@assets/images/casanova-rentacar.png';

import i18n from '@utils/i18n';

function SignaturesCompletedPage() {
  return (
    <>
      <SimpleHeader infoText={i18n('SIGNATURES_COMPLETED__HEADER__TITLE')} />
      <div className="flex justify-center">
        <div className="flex justify-center items-center w-full md:w-5/6 mt-3">
          <Card className="bg-white">
            <div className="flex flex-col items-center md:py-6 md:px-14">
              <div className="w-64">
                <img className="w-full" src={rentaCar} alt="Casanova logo" />
              </div>
              <div className="p-6 text-center">
                <p className="text-yellow-500 text-2xl font-bold">
                  {i18n('SIGNATURES_COMPLETED__BODY__TITLE')}
                </p>
                <p className="text-lg font-light tracking-wide mt-9">
                  {i18n('SIGNATURES_COMPLETED__BODY__SUBTITLE')}
                  <br />
                  {i18n('SIGNATURES_COMPLETED__BODY__SUBTITLE_COMPLEMENT')}
                </p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}

export default SignaturesCompletedPage;
