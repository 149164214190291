import React from 'react';
import { Route } from 'react-router-dom';
import Signatures from '@pages/Signatures';
import SignaturesCompleted from '@pages/SignaturesCompleted';

export const routes = [
  {
    path: '/signatures/completed',
    component: SignaturesCompleted,
  },
  {
    path: '/signatures',
    component: Signatures,
  },
];

export function RouteWithSubRoutes(route) {
  const { path, routes: currentRoutes } = route;
  return (
    <Route
      path={path}
      render={(props) => <route.component {...props} routes={currentRoutes} />}
    />
  );
}
