import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import signatures from './signatures';
import app from './app';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    signatures,
    app,
  });

export default createRootReducer;
