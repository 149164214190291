import { put, call, takeLatest, select } from 'redux-saga/effects';
import _get from 'lodash/get';
import { actionTypes, actions } from '@store/actions/signatures';
import { actions as appActions } from '@store/actions/app';
import { getPendingSignatures, saveSignature } from '@services/signatures';

// TODO: Fix connected-react-router not working actions (like put or goBack) on sagas

function* getPendingSignaturesSagas({ payload }) {
  try {
    yield put(appActions.showLoader());
    const data = yield call(getPendingSignatures, payload);
    if (data.completed) {
      window.location.href = '/signatures/completed';
    }
    yield put(actions.getFormByTokenSuccess(data));
  } catch (error) {
    yield put(actions.getFormByTokenFailed(error));
  } finally {
    yield put(appActions.hideLoader());
  }
}

function* saveSignatureSagas({ payload }) {
  try {
    yield put(appActions.showLoader());
    const request = {
      checkBox: payload.checkbox,
      signature: payload.signature,
    };
    const currentInput = yield select((state) =>
      _get(state, 'signatures.form.data.inputs.0', {}),
    );
    const data = yield call(saveSignature, currentInput.uuid, request);
    yield put(actions.saveFormInputSuccess(data));

    window.location.reload();
  } catch (error) {
    yield put(actions.saveFormInputFailed(error));
  } finally {
    yield put(appActions.hideLoader());
  }
}

export default function* authSaga() {
  yield takeLatest(actionTypes.GET_FORM_BY_TOKEN, getPendingSignaturesSagas);
  yield takeLatest(actionTypes.SAVE_FORM_INPUT, saveSignatureSagas);
}
