import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { actions } from '@store/actions/signatures';
import Signatures from './Signatures';

export default compose(
  connect(
    (state) => ({
      showLoader: _get(state, 'app.loader', false),
      currentInput: _get(state, 'signatures.form.data.inputs.0', {}),
      form: _get(state, 'signatures.form.data', {}),
      formSuccess: _get(state, 'signatures.form.success', {}),
    }),
    {
      getFormByToken: actions.getFormByToken,
      saveFormInput: actions.saveFormInput,
    },
  ),
)(Signatures);
