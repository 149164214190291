export const actionTypes = {
  LOGIN: 'auth/LOGIN',
  LOGIN_SUCCESS: 'auth/LOGIN_SUCCESS',
  LOGIN_FAILED: 'auth/LOGIN_FAILED',
  REHYDRATE: 'auth/REHYDRATE',
};

export const actions = {
  login: (payload) => ({ type: actionTypes.LOGIN, payload }),
  loginFailed: (payload) => ({ type: actionTypes.LOGIN_FAILED, payload }),
  loginSuccess: (payload) => ({ type: actionTypes.LOGIN_SUCCESS, payload }),
  rehydrate: () => ({ type: actionTypes.REHYDRATE }),
};
